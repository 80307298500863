import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: 'fd576a36-7d3a-4d47-90b5-4e9b0291ae24', // Replace with your Application (client) ID from Azure AD
    authority: 'https://login.microsoftonline.com/common', // This authority will work for most scenarios
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
};