import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { msalConfig } from './authConfig'; // Ensure this path is correct
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import DataTable from './DataTable'; // Assuming you have this component
import './App.css';

const msalInstance = new PublicClientApplication(msalConfig);
const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginPopup().catch(e => {
      console.error(e);
    });
  };

  return <button onClick={handleLogin}>Sign In</button>;
};

const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutPopup().catch(e => {
      console.error(e);
    });
  };

  return <button onClick={handleLogout}>Sign Out</button>;
};

const Content = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  useEffect(() => {
    const fetchUser = async () => {
      const loggedInUser = await getLoggedInUser();
      setUser(loggedInUser);
    };

    fetchUser();
  }, []); // Empty dependency array means this effect runs once on mount
  useEffect(() => {
    axios.get('https://isabella.azurewebsites.net/api/Harold?Strategy=FYM&RequestType=Overview')
      .then(response => {
        try {
          const jsonData = response.data.match(/\[.*\]/);
          if (jsonData) {
            const parsedData = JSON.parse(jsonData[0]);
            setData(parsedData);
          } else {
            throw new Error("Invalid data format");
          }
          setLoading(false);
        } catch (err) {
          console.error('Error parsing data:', err);
          setError('Error parsing data');
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    
    <>
    <div>
      <h1>Welcome to Our App</h1>
      {user && (
        <div>
          <h2>User Information</h2>
          <p>Name: {user.name}</p>
          <p>Email: {user.username}</p>
          {/* Display more user information as needed */}
        </div>
      )}
    </div>
    <div>
      <header className="App-header">
        <h1>API Data</h1>
        {Array.isArray(data) ? <DataTable data={data} /> : <pre>{JSON.stringify(data, null, 2)}</pre>}
      </header>
    </div>
  </>
  );
};
async function getLoggedInUser() {
    try {
        // Attempt to acquire token silently
        const account = msalInstance.getAllAccounts()[0]; // Get the first account. Adjust as needed for your app's account management strategy.
        if (account) {
            const silentRequest = {
                scopes: ['User.Read'], // Minimal scopes for reading user's profile
                account: account,
            };

            const response = await msalInstance.acquireTokenSilent(silentRequest);
            console.log ('response', response);
            // Optionally, call Microsoft Graph or another API with the token to get additional user details
            console.log ('account', account);
            return account; // This returns the account object. Adjust as needed for your specific needs.
           
        }
    } catch (error) {
        console.error('Failed to retrieve user', error);
        // Handle error, possibly initiate interactive sign-in
        return null;
    }
}
  
function App() {
    
  const isAuthenticated = useIsAuthenticated();
 
  return (
    <div className="App">
      {isAuthenticated ? (
        <>
          <SignOutButton />
          <Content />
        </>
      ) : (
        <SignInButton />
      )}
    </div>
  );
}

const AppWithMsal = () => (
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
);

export default AppWithMsal;